
export class IconFont {

	constructor() {
		const head = document.getElementsByTagName('head')[0];
		const link = document.createElement('link');

		link.rel = 'stylesheet';
		link.type = 'text/css';
		link.href = 'https://unpkg.com/@phosphor-icons/web@2.0.3/src/thin/style.css';
		head.appendChild(link);
	}

}
