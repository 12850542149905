
export class LanguagesSwitcher {

	wrapper: HTMLElement;
	trigger: HTMLElement;
	list: HTMLElement;

	constructor(wrapper: HTMLElement) {
		this.wrapper = wrapper;
		this.trigger = this.wrapper.querySelector('button');

		this.trigger.addEventListener('click', (ev: MouseEvent) => {
			ev.preventDefault();

			this.toggleLangs();
		});

		this.onClickOutside();
	}


	toggleLangs() {
		if ( this.wrapper.classList.contains('shown') ) {
			this.hideLangs();
		} else {
			this.wrapper.classList.add('shown');
		}
	}

	hideLangs() {
		this.wrapper.classList.remove('shown');
	}

	onClickOutside() {
		document.addEventListener('click', event => {
			if (!this.wrapper.contains((<HTMLElement>event.target))) {
				this.hideLangs();
			}
		});
	}

}