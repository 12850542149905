

export class FactotumEcommerce {

	constructor() {

		import('./components/cart/cart').then( c => new c.Cart() );

	}

}


