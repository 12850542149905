import { gsap } from 'gsap';


export class Menu {

	static trigger: HTMLButtonElement;
	static subnavTriggers: NodeListOf<HTMLButtonElement>;
	static header: HTMLElement;
	static container: HTMLElement;
	static onMobile: boolean;


	static setupMenu() {
		Menu.trigger   = document.querySelector('.menu-btn');
		Menu.subnavTriggers = document.querySelectorAll('.toggle-sub-nav');
		Menu.header    = document.querySelector('header');
		Menu.container = document.querySelector('.menu-container');

		Menu.onMobile = (screen.availWidth < 1024);
		window.screen.orientation.addEventListener('change', (ev) => {
			Menu.onMobile = (screen.availWidth < 1024);
			Menu.resetSubNav();
		});

		Menu.attachEventListeners();
	}


	static attachEventListeners() {

		if ( Menu.trigger ) {
			Menu.trigger.addEventListener( 'click', () => {
				if ( Menu.trigger.classList.contains( 'active' ) ) {
					Menu.closeMenu();
				} else {
					Menu.openMenu();
				}
			});
		}

		if ( Menu.subnavTriggers ) {
			Menu.subnavTriggers.forEach( trigger => {
				trigger.addEventListener( 'click', (ev: MouseEvent) => {
					ev.preventDefault();

					if ( Menu.onMobile ) {
						if (trigger.classList.contains('active')) {
							Menu.closeSubNav(trigger);
						} else {
							Menu.openSubNav(trigger);
						}
					}
				})
			});
		}

	}


	static openMenu() {
		document.body.classList.add('menu-open');
		Menu.trigger.classList.add('active');
		gsap.to( Menu.container, { left: 0, opacity: 1, duration: 1 });
		gsap.to( 'header svg path', { fill: '#173D47' });

		gsap.fromTo('.sub-nav', {
			opacity: 0,
			left: -30
		}, {
			delay: .5,
			duration: 1.5,
			opacity: 1,
			left: 0,
			stagger: .15,
			ease: 'easeOutExpo'
		});


		gsap.fromTo('.bottom-menu .cta', {
			opacity: 0,
			left: -30
		}, {
			delay: .5,
			duration: 1.5,
			opacity: 1,
			left: 0,
			stagger: .15,
			ease: 'easeOutExpo'
		});

		let basicProps: any = {
			delay: .5,
			duration: 1.5,
			stagger: .15,
		};

		if ( Menu.onMobile ) {
			basicProps['--bar-width'] = '100%';
		} else {
			basicProps['--bar-height'] = '100%';
		}

		gsap.to('.sub-nav', basicProps);

	}


	static closeMenu( ev?: MouseEvent ) {

		gsap.to('.sub-nav', {
			opacity: 0,
			left: -30,
			stagger: .15,
			ease: 'easeOutExpo',
			onComplete: () => {
				document.body.classList.remove('menu-open');
			}
		});


		gsap.to('.bottom-menu .cta', {
			opacity: 0,
			left: -30,
			stagger: .15,
			ease: 'easeOutExpo'
		});

		let basicProps: any = {
			delay: .5,
			duration: 1.5,
			stagger: {
				amount: .15,
				from: 'end'
			}
		};

		if ( Menu.onMobile ) {
			basicProps['--bar-width'] = 0;
		} else {
			basicProps['--bar-height'] = 0;
		}

		gsap.to('.sub-nav', basicProps);

		gsap.to( 'header svg path', { fill: '#B0CCD7' });
		Menu.trigger.classList.remove('active');
		gsap.to( Menu.container, { left: '-100vw', opacity: 0, duration: 1, delay: 1 });
	}


	static openSubNav(trigger: HTMLButtonElement) {
		const subNav = <HTMLElement>trigger.closest('.sub-nav');

		trigger.querySelector('.plus-toggle').classList.add('active');
		trigger.classList.add('active');


		gsap.to(subNav, { height: 'auto' });

		gsap.fromTo(subNav.querySelectorAll('a'), {
			opacity: 0,
			left: -30
		}, {
			opacity: 1,
			left: 0,
			stagger: .15,
			ease: 'easeOutExpo'
		});
	}


	static closeSubNav(trigger: HTMLButtonElement) {
		const subNav = <HTMLElement>trigger.closest('.sub-nav');
		gsap.to(subNav, { height: 50, delay: .4 });

		gsap.fromTo(subNav.querySelectorAll('a'), {
			opacity: 1,
			left: 0
		}, {
			opacity: 0,
			left: -30,
			stagger: {
				from: 'end',
				amount: .15
			},
			ease: 'easeOutExpo',
			onComplete: () => {
				trigger.querySelector('.plus-toggle').classList.remove('active');
				trigger.classList.remove('active');
				subNav.classList.remove('active');
			}
		});
	}


	static resetSubNav() {
		Menu.container.querySelectorAll('.sub-nav').forEach( (subNav: HTMLElement) => {
			subNav.querySelector('button').removeAttribute('style');
			subNav.querySelectorAll('a').forEach( link => link.removeAttribute('style') );
		});
	}


	// TODO: rifinire queste 2
	static setMenuItemActive( trigger: HTMLElement ) {
		Menu.container.querySelectorAll('nav a').forEach( t => t.classList.remove('active') );
		trigger.classList.add('active');
	}


}


