
import '../scss/btf/btf.scss';

import { Menu } from './components/menu/menu';
import { Header } from "./components/header/header";
import { ScrollTo } from "./components/scroll-to/scroll-to";
import { AsyncNavigation } from "./async-navigation/async-navigation";
import { IconFont } from "./icon-font";
import { LanguagesSwitcher } from "./components/languages-switcher/languages-switcher";
import { FactotumEcommerce } from "./ecommerce/ecommerce";


document.addEventListener('DOMContentLoaded', function() {

	const icontFont = new IconFont();

	if ( document.querySelector('.menu-btn') ) {
		Menu.setupMenu();
	}

	if ( document.querySelector('header') ) {
		new Header();
	}

	if (document.querySelector('.languages-switcher')) {
		document.querySelectorAll('.languages-switcher').forEach( (ls: HTMLElement) => {
			new LanguagesSwitcher( ls );
		});
	}


	AsyncNavigation.initAsyncNavigation();

	if ( document.querySelector('.scroll-to') ) {
		document.querySelectorAll('.scroll-to').forEach( (el: HTMLElement) => new ScrollTo(el) );
	}

	let ecomm = new FactotumEcommerce();

}, false);

